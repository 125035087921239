code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*
.topbar {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 0 28px;
  background: var(--surface-card);
  box-shadow: rgb(0 0 0 / 3%) 0px 4px 10px, rgb(0 0 0 / 6%) 0px 0px 2px,
    rgb(0 0 0 / 12%) 0px 2px 6px;
}

.p-panelmenu {
  position: fixed;
  width: 250px;
  height: 100%;
  top: 70px;
  left: 0;
  z-index: 98;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.menu a:link,
.menu a:visited {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 1rem;
}

.menu a.active div {
  background-color: var(--blue-100) !important;
}

.menu a:hover div {
  background-color: var(--blue-200) !important;
}

.menu-item-org,
.menu-item-project {
  width: 100%;
} */

/* .content {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  margin-left: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
} */

.contentbox {
  padding: 15px;
}

.brand {
  font-family: "Covered By Your Grace", cursive !important;
  padding: 0 3rem 0 1rem;
}

main {
  padding: 0 3rem;
}

/* .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header > a,
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a,
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header > a {
  border-right: 0;
  background-color: inherit;
}
.p-panelmenu .p-panelmenu-header:first-child > a {
  border-top: 0;
}
.p-panelmenu .p-panelmenu-content {
  border-right: 0;
} */

button.profileButton, button.profileButton:hover, button.profileButton:focus {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}
